import { GetSpeciesDataByName,  GetSpanishName } from "./PokemonSpeciesData";

/**
 * Devuelve un valor entre 1 y 6 referente al "valor de frecuencia"
 * @param dexNum 
 */
export const GetFrequencyByDexNum = (dexNum) => {
    for (let i = 0 ; i < pokemonFrequencies.length ; i++) {
        const foundPokemon = pokemonFrequencies[i].find(pokemon => pokemon.dexNum === dexNum);

        if (foundPokemon) {
            return i + 1;
        }
    }
    return 0;
}


/**
 * Devuelve un valor entre 1 y 6 referente al "valor de frecuencia"
 * @param name 
 */
export const GetFrequencyByName = (name) => {
    for (let i = 0 ; i < pokemonFrequencies.length ; i++) {
        const foundPokemon = pokemonFrequencies[i].find(pokemon => pokemon.name=== name);

        if (foundPokemon) {
            return i + 1;
        }
    }
    return 0;
}

/**
 * Devuelve un valor entre 1 y 6 referente al "valor de frecuencia"
 * @param data 
 */
export const GetFrequencyAsync = async (data) => {
    try {
        const poke = await GetSpeciesDataByName(data);
        const realName = GetSpanishName(poke);
        return GetFrequencyByName(realName);
    } catch (error) {
        console.error("ERROR GetFrequencyAsync: " + error)
        return null;
    }
}

export const GetPokemonArrayByFrequency = (frequencyIndex) =>
{
    return pokemonFrequencies[frequencyIndex];
}


const pokemonFrequencies = [
    [
        {"name": "Caterpie", "dexNum": 10},
        {"name": "Weedle", "dexNum": 13},
        {"name": "Pidgey", "dexNum": 16},
        {"name": "Rattata", "dexNum": 19},
        {"name": "Spearow", "dexNum": 21},
        {"name": "Ekans", "dexNum": 23},
        {"name": "Nidoran♀", "dexNum": 29},
        {"name": "Nidoran♂", "dexNum": 32},
        {"name": "Zubat", "dexNum": 41},
        {"name": "Poliwag", "dexNum": 60},
        {"name": "Bellsprout", "dexNum": 69},
        {"name": "Geodude", "dexNum": 74},
        {"name": "Sandshrew", "dexNum": 27},
        {"name": "Magikarp", "dexNum": 129},
        {"name": "Sentret", "dexNum": 161},
        {"name": "Hoothoot", "dexNum": 163},
        {"name": "Ledyba", "dexNum": 165},
        {"name": "Spinarak", "dexNum": 167},
        {"name": "Chinchou", "dexNum": 170},
        {"name": "Pichu", "dexNum": 172},
        {"name": "Cleffa", "dexNum": 173},
        {"name": "Igglybuff", "dexNum": 174},
        {"name": "Togepi", "dexNum": 175},
        {"name": "Hoppip", "dexNum": 187},
        {"name": "Sunkern", "dexNum": 191},
        {"name": "Tyrogue", "dexNum": 236},
        {"name": "Smoochum", "dexNum": 238},
        {"name": "Elekid", "dexNum": 239},
        {"name": "Magby", "dexNum": 240},
        {"name": "Poochyena", "dexNum": 261},
        {"name": "Zigzagoon", "dexNum": 263},
        {"name": "Wurmple", "dexNum": 265},
        {"name": "Slakoth", "dexNum": 287},
        {"name": "Whismur", "dexNum": 293},
        {"name": "Azurill", "dexNum": 298},
        {"name": "Feebas", "dexNum": 349},
        {"name": "Wynaut", "dexNum": 360},
        {"name": "Starly", "dexNum": 396},
        {"name": "Bidoof", "dexNum": 399},
        {"name": "Kricketot", "dexNum": 401},
        {"name": "Budew", "dexNum": 406},
        {"name": "Chingling", "dexNum": 433},
        {"name": "Bonsly", "dexNum": 438},
        {"name": "Mime Jr.", "dexNum": 439},
        {"name": "Happiny", "dexNum": 440},
        {"name": "Mantyke", "dexNum": 458},
        {"name": "Patrat", "dexNum": 504},
        {"name": "Lillipup", "dexNum": 506},
        {"name": "Purrloin", "dexNum": 509},
        {"name": "Pansage", "dexNum": 511},
        {"name": "Pansear", "dexNum": 513},
        {"name": "Panpour", "dexNum": 515},
        {"name": "Pidove", "dexNum": 519},
        {"name": "Sewaddle", "dexNum": 540},
        {"name": "Bunnelby", "dexNum": 659},
        {"name": "Fletchling", "dexNum": 661},
        {"name": "Scatterbug", "dexNum": 664},
        {"name": "Pikipek", "dexNum": 731},
        {"name": "Yungoos", "dexNum": 734},
        {"name": "Grubbin", "dexNum": 736},
        {"name": "Skwovet", "dexNum": 819},
        {"name": "Rookidee", "dexNum": 821},
        {"name": "Blipbug", "dexNum": 824},
        {"name": "Wooloo", "dexNum": 831},
        {"name": "Lechonk", "dexNum": 915},
        {"name": "Tarountula", "dexNum": 917},
        {"name": "Nymble", "dexNum": 919},
        {"name": "Pawmi", "dexNum": 921},
        {"name": "Smoliv", "dexNum": 928}
    ],
    /* Tier 2 */ [
        {"name": "Cubchoo", "dexNum": 613},
        
        {"name": "Shelmet", "dexNum": 616},
        {"name": "Oinkologne", "dexNum": 916},
        {"name": "Spidops", "dexNum": 918},
        {"name": "Pawmo", "dexNum": 922},
        {"name": "Tandemaus", "dexNum": 924},
        {"name": "Fidough", "dexNum": 926},
        {"name": "Dolliv", "dexNum": 929},
        {"name": "Nacli", "dexNum": 932},
        {"name": "Charcadet", "dexNum": 935},
        {"name": "Tadbulb", "dexNum": 938},
        {"name": "Wattrel", "dexNum": 940},
        {"name": "Maschiff", "dexNum": 942},
        {"name": "Shroodle", "dexNum": 944},
        {"name": "Bramblin", "dexNum": 946},
        {"name": "Toedscool", "dexNum": 948},
        {"name": "Capsakid", "dexNum": 951},
        {"name": "Rellor", "dexNum": 953},
        {"name": "Flittle", "dexNum": 955},
        {"name": "Wiglett", "dexNum": 960},
        {"name": "Finizen", "dexNum": 963},
        {"name": "Varoom", "dexNum": 965},
        {"name": "Glimmet", "dexNum": 969},
        {"name": "Greavard", "dexNum": 971},
        {"name": "Cetoddle", "dexNum": 974},
        {"name": "Poltchageist", "dexNum": 1012},
        {"name": "Sinistea", "dexNum": 854},
        {"name": "Greedent", "dexNum": 820},
        {"name": "Corvisquire", "dexNum": 822},
        {"name": "Dottler", "dexNum": 825},
        {"name": "Nickit", "dexNum": 827},
        {"name": "Gossifleur", "dexNum": 829},
        {"name": "Dubwool", "dexNum": 832},
        {"name": "Chewtle", "dexNum": 833},
        {"name": "Yamper", "dexNum": 835},
        {"name": "Rolycoly", "dexNum": 837},
        {"name": "Applin", "dexNum": 840},
        {"name": "Silicobra", "dexNum": 843},
        {"name": "Arrokuda", "dexNum": 846},
        {"name": "Toxel", "dexNum": 848},
        {"name": "Sizzlipede", "dexNum": 850},
        {"name": "Clobbopus", "dexNum": 852},
        {"name": "Hatenna", "dexNum": 856},
        {"name": "Impidimp", "dexNum": 859},
        {"name": "Milcery", "dexNum": 868},
        {"name": "Snom", "dexNum": 872},
        {"name": "Cufant", "dexNum": 878},
        {"name": "Gumshoos", "dexNum": 735},
        {"name": "Charjabug", "dexNum": 737},
        {"name": "Crabrawler", "dexNum": 739},
        {"name": "Cutiefly", "dexNum": 742},
        {"name": "Rockruff", "dexNum": 744},
        {"name": "Mareanie", "dexNum": 747},
        {"name": "Mudbray", "dexNum": 749},
        {"name": "Dewpider", "dexNum": 751},
        {"name": "Fomantis", "dexNum": 753},
        {"name": "Morelull", "dexNum": 755},
        {"name": "Salandit", "dexNum": 757},
        {"name": "Stufful", "dexNum": 759},
        {"name": "Bounsweet", "dexNum": 761},
        {"name": "Wimpod", "dexNum": 767},
        {"name": "Sandygast", "dexNum": 769},
        {"name": "Diggersby", "dexNum": 660},
        {"name": "Fletchinder", "dexNum": 662},
        {"name": "Spewpa", "dexNum": 665},
        {"name": "Litleo", "dexNum": 667},
        {"name": "Flabébé", "dexNum": 669},
        {"name": "Skiddo", "dexNum": 672},
        {"name": "Pancham", "dexNum": 674},
        {"name": "Espurr", "dexNum": 677},
        {"name": "Honedge", "dexNum": 679},
        {"name": "Spritzee", "dexNum": 682},
        {"name": "Swirlix", "dexNum": 684},
        {"name": "Inkay", "dexNum": 686},
        {"name": "Binacle", "dexNum": 688},
        {"name": "Skrelp", "dexNum": 690},
        {"name": "Clauncher", "dexNum": 692},
        {"name": "Helioptile", "dexNum": 694},
        {"name": "Phantump", "dexNum": 708},
        {"name": "Pumpkaboo", "dexNum": 710},
        {"name": "Bergmite", "dexNum": 712},
        {"name": "Trumbeak", "dexNum": 732},
        {"name": "Watchog", "dexNum": 505},
        {"name": "Herdier", "dexNum": 507},
        {"name": "Liepard", "dexNum": 510},
        {"name": "Simisage", "dexNum": 512},
        {"name": "Simisear", "dexNum": 514},
        {"name": "Simipour", "dexNum": 516},
        {"name": "Munna", "dexNum": 517},
        {"name": "Tranquill", "dexNum": 520},
        {"name": "Blitzle", "dexNum": 522},
        {"name": "Roggenrola", "dexNum": 524},
        {"name": "Woobat", "dexNum": 527},
        {"name": "Drilbur", "dexNum": 529},
        {"name": "Timburr", "dexNum": 532},
        {"name": "Tympole", "dexNum": 535},
        {"name": "Swadloon", "dexNum": 541},
        {"name": "Venipede", "dexNum": 543},
        {"name": "Cottonee", "dexNum": 546},
        {"name": "Petilil", "dexNum": 548},
        {"name": "Sandile", "dexNum": 551},
        {"name": "Darumaka", "dexNum": 554},
        {"name": "Dwebble", "dexNum": 557},
        {"name": "Scraggy", "dexNum": 559},
        {"name": "Yamask", "dexNum": 562},
        {"name": "Trubbish", "dexNum": 568},
        {"name": "Zorua", "dexNum": 570},
        {"name": "Minccino", "dexNum": 572},
        {"name": "Gothita", "dexNum": 574},
        {"name": "Solosis", "dexNum": 577},
        {"name": "Ducklett", "dexNum": 580},
        {"name": "Vanillite", "dexNum": 582},
        {"name": "Deerling", "dexNum": 585},
        {"name": "Karrablast", "dexNum": 588},
        {"name": "Foongus", "dexNum": 590},
        {"name": "Frillish", "dexNum": 592},
        {"name": "Joltik", "dexNum": 595},
        {"name": "Ferroseed", "dexNum": 597},
        {"name": "Klink", "dexNum": 599},
        {"name": "Tynamo", "dexNum": 602},
        {"name": "Elgyem", "dexNum": 605},
        {"name": "Cubchoo", "dexNum": 607},
        {"name": "Shelmet", "dexNum": 610},
        {"name": "Pawniard", "dexNum": 624},
        {"name": "Staravia", "dexNum": 397},
        {"name": "Kricketune", "dexNum": 402},
        {"name": "Shinx", "dexNum": 403},
        {"name": "Burmy", "dexNum": 412},
        {"name": "Combee", "dexNum": 415},
        {"name": "Buizel", "dexNum": 418},
        {"name": "Cherubi", "dexNum": 420},
        {"name": "Shellos", "dexNum": 422},
        {"name": "Drifloon", "dexNum": 425},
        {"name": "Buneary", "dexNum": 427},
        {"name": "Glameow", "dexNum": 431},
        {"name": "Stunky", "dexNum": 434},
        {"name": "Bronzor", "dexNum": 436},
        {"name": "Chatot", "dexNum": 441},
        {"name": "Munchlax", "dexNum": 446},
        {"name": "Riolu", "dexNum": 447},
        {"name": "Hippopotas", "dexNum": 449},
        {"name": "Skorupi", "dexNum": 451},
        {"name": "Croagunk", "dexNum": 453},
        {"name": "Finneon", "dexNum": 456},
        {"name": "Snover", "dexNum": 459},
        {"name": "Mightyena", "dexNum": 262},
        {"name": "Linoone", "dexNum": 264},
        {"name": "Silcoon", "dexNum": 266},
        {"name": "Cascoon", "dexNum": 268},
        {"name": "Lotad", "dexNum": 270},
        {"name": "Seedot", "dexNum": 273},
        {"name": "Taillow", "dexNum": 276},
        {"name": "Wingull", "dexNum": 278},
        {"name": "Ralts", "dexNum": 280},
        {"name": "Surskit", "dexNum": 283},
        {"name": "Shroomish", "dexNum": 285},
        {"name": "Nincada", "dexNum": 290},
        {"name": "Loudred", "dexNum": 294},
        {"name": "Makuhita", "dexNum": 296},
        {"name": "Skitty", "dexNum": 300},
        {"name": "Aron", "dexNum": 304},
        {"name": "Meditite", "dexNum": 307},
        {"name": "Electrike", "dexNum": 309},
        {"name": "Gulpin", "dexNum": 316},
        {"name": "Carvanha", "dexNum": 318},
        {"name": "Wailmer", "dexNum": 320},
        {"name": "Numel", "dexNum": 322},
        {"name": "Spoink", "dexNum": 325},
        {"name": "Trapinch", "dexNum": 328},
        {"name": "Cacnea", "dexNum": 331},
        {"name": "Swablu", "dexNum": 333},
        {"name": "Barboach", "dexNum": 339},
        {"name": "Corphish", "dexNum": 341},
        {"name": "Baltoy", "dexNum": 343},
        {"name": "Shuppet", "dexNum": 353},
        {"name": "Duskull", "dexNum": 355},
        {"name": "Spheal", "dexNum": 363},
        {"name": "Clamperl", "dexNum": 366},
        {"name": "Furret", "dexNum": 162},
        {"name": "Noctowl", "dexNum": 164},
        {"name": "Ledian", "dexNum": 166},
        {"name": "Ariados", "dexNum": 168},
        {"name": "Lanturn", "dexNum": 171},
        {"name": "Natu", "dexNum": 177},
        {"name": "Mareep", "dexNum": 179},
        {"name": "Bellossom", "dexNum": 182},
        {"name": "Marill", "dexNum": 183},
        {"name": "Sudowoodo", "dexNum": 185},
        {"name": "Skiploom", "dexNum": 188},
        {"name": "Aipom", "dexNum": 190},
        {"name": "Sunflora", "dexNum": 192},
        {"name": "Yanma", "dexNum": 193},
        {"name": "Wooper", "dexNum": 194},
        {"name": "Murkrow", "dexNum": 198},
        {"name": "Misdreavus", "dexNum": 200},
        {"name": "Girafarig", "dexNum": 203},
        {"name": "Pineco", "dexNum": 204},
        {"name": "Snubbull", "dexNum": 209},
        {"name": "Teddiursa", "dexNum": 216},
        {"name": "Slugma", "dexNum": 218},
        {"name": "Swinub", "dexNum": 220},
        {"name": "Remoraid", "dexNum": 223},
        {"name": "Houndour", "dexNum": 228},
        {"name": "Phanpy", "dexNum": 231},
        {"name": "Smeargle", "dexNum": 235},
        {"name": "Metapod", "dexNum": 11},
        {"name": "Kakuna", "dexNum": 14},
        {"name": "Pidgeotto", "dexNum": 17},
        {"name": "Arbok", "dexNum": 24},
        {"name": "Nidorina", "dexNum": 30},
        {"name": "Nidorino", "dexNum": 33},
        {"name": "Golbat", "dexNum": 42},
        {"name": "Gloom", "dexNum": 44},
        {"name": "Poliwhirl", "dexNum": 61},
        {"name": "Kadabra", "dexNum": 64},
        {"name": "Machoke", "dexNum": 67},
        {"name": "Weepinbell", "dexNum": 70},
        {"name": "Graveler", "dexNum": 75},
        {"name": "Chansey", "dexNum": 113},
        {"name": "Porygon", "dexNum": 137},
        {"name": "Pikachu", "dexNum": 25},
        {"name": "Clefairy", "dexNum": 35},
        {"name": "Vulpix", "dexNum": 37},
        {"name": "Jigglypuff", "dexNum": 39},
        {"name": "Oddish", "dexNum": 43},
        {"name": "Paras", "dexNum": 46},
        {"name": "Venonat", "dexNum": 48},
        {"name": "Diglett", "dexNum": 50},
        {"name": "Meowth", "dexNum": 52},
        {"name": "Psyduck", "dexNum": 54},
        {"name": "Mankey", "dexNum": 56},
        {"name": "Growlithe", "dexNum": 58},
        {"name": "Abra", "dexNum": 63},
        {"name": "Machop", "dexNum": 66},
        {"name": "Tentacool", "dexNum": 72},
        {"name": "Ponyta", "dexNum": 77},
        {"name": "Slowpoke", "dexNum": 79},
        {"name": "Magnemite", "dexNum": 81},
        {"name": "Doduo", "dexNum": 84},
        {"name": "Seel", "dexNum": 86},
        {"name": "Grimer", "dexNum": 88},
        {"name": "Shellder", "dexNum": 90},
        {"name": "Gastly", "dexNum": 92},
        {"name": "Onix", "dexNum": 95},
        {"name": "Drowzee", "dexNum": 96},
        {"name": "Krabby", "dexNum": 98},
        {"name": "Voltorb", "dexNum": 100},
        {"name": "Exeggcute", "dexNum": 102},
        {"name": "Cubone", "dexNum": 104},
        {"name": "Koffing", "dexNum": 109},
        {"name": "Rhyhorn", "dexNum": 111},
        {"name": "Tangela", "dexNum": 114},
        {"name": "Horsea", "dexNum": 116},
        {"name": "Goldeen", "dexNum": 118},
        {"name": "Staryu", "dexNum": 120},
        {"name": "Omanyte", "dexNum": 138},
        {"name": "Kabuto", "dexNum": 140},
        {"name": "Tinkatink", "dexNum": 957},
        {"name": "Nosepass", "dexNum": 299},
        {"name": "Eevee", "dexNum": 133},
        {"name": "Frigibax", "dexNum": 996},
        {"name": "Dreepy", "dexNum": 885},
        {"name": "Jangmo-o", "dexNum": 782},
        {"name": "Grookey", "dexNum": 810},
        {"name": "Scorbunny", "dexNum": 813},
        {"name": "Sobble", "dexNum": 816},
        {"name": "Noibat", "dexNum": 714},
        {"name": "Rowlet", "dexNum": 722},
        {"name": "Litten", "dexNum": 725},
        {"name": "Popplio", "dexNum": 728},
        {"name": "Litwick", "dexNum": 607},
        {"name": "Axew", "dexNum": 610},
        {"name": "Goomy", "dexNum": 704},
        {"name": "Deino", "dexNum": 633},
        {"name": "Fennekin", "dexNum": 653},
        {"name": "Froakie", "dexNum": 656},
        {"name": "Snivy", "dexNum": 495},
        {"name": "Tepig", "dexNum": 498},
        {"name": "Oshawott", "dexNum": 501},
        {"name": "Dratini", "dexNum": 147},
        {"name": "Chikorita", "dexNum": 152},
        {"name": "Cyndaquil", "dexNum": 155},
        {"name": "Totodile", "dexNum": 158},
        {"name": "Chespin", "dexNum": 650},
        {"name": "Bulbasaur", "dexNum": 1},
        {"name": "Squirtle", "dexNum": 7},
        {"name": "Charmander", "dexNum": 4},
        {"name": "Sprigatito", "dexNum": 906},
        {"name": "Fuecoco", "dexNum": 909},
        {"name": "Quaxly", "dexNum": 912},
        {"name": "Larvitar", "dexNum": 246},
        {"name": "Treecko", "dexNum": 252},
        {"name": "Torchic", "dexNum": 255},
        {"name": "Mudkip", "dexNum": 258},
        {"name": "Bagon", "dexNum": 371},
        {"name": "Beldum", "dexNum": 374},
        {"name": "Turtwig", "dexNum": 387},
        {"name": "Chimchar", "dexNum": 390},
        {"name": "Piplup", "dexNum": 393},
        {"name": "Gible", "dexNum": 443}
    ],
    /* Tier 3 */ [
        
        {"name": "Xatu", "dexNum": 178},
        {"name": "Golem", "dexNum": 76},
        {"name": "Politoed", "dexNum": 186},
        {"name": "Pyukumuku", "dexNum": 771},
        {"name": "Kangaskhan", "dexNum": 115},
        {"name": "Lokix", "dexNum": 920},
        {"name": "Pawmot", "dexNum": 923},
        {"name": "Maushold", "dexNum": 925},
        {"name": "Dachsbun", "dexNum": 927},
        {"name": "Arboliva", "dexNum": 930},
        {"name": "Squawkabilly", "dexNum": 931},
        {"name": "Naclstack", "dexNum": 933},
        {"name": "Bellibolt", "dexNum": 939},
        {"name": "Kilowattrel", "dexNum": 941},
        {"name": "Mabosstiff", "dexNum": 943},
        {"name": "Grafaiai", "dexNum": 945},
        {"name": "Brambleghast", "dexNum": 947},
        {"name": "Toedscruel", "dexNum": 949},
        {"name": "Klawf", "dexNum": 950},
        {"name": "Scovillain", "dexNum": 952},
        {"name": "Rabsca", "dexNum": 954},
        {"name": "Wugtrio", "dexNum": 961},
        {"name": "Bombirdier", "dexNum": 962},
        {"name": "Orthworm", "dexNum": 968},
        {"name": "Glimmora", "dexNum": 970},
        {"name": "Houndstone", "dexNum": 972},
        {"name": "Flamigo", "dexNum": 973},
        {"name": "Cetitan", "dexNum": 975},
        {"name": "Veluza", "dexNum": 976},
        {"name": "Dondozo", "dexNum": 977},
        {"name": "Tatsugiri", "dexNum": 978},
        {"name": "Gimmighoul", "dexNum": 999},
        {"name": "Dipplin", "dexNum": 1011},
        {"name": "Poltchageist", "dexNum": 1012},
        {"name": "Corviknight", "dexNum": 823},
        {"name": "Orbeetle", "dexNum": 826},
        {"name": "Thievul", "dexNum": 828},
        {"name": "Eldegoss", "dexNum": 830},
        {"name": "Drednaw", "dexNum": 834},
        {"name": "Boltund", "dexNum": 836},
        {"name": "Carkol", "dexNum": 838},
        {"name": "Flapple", "dexNum": 841},
        {"name": "Appletun", "dexNum": 842},
        {"name": "Sandaconda", "dexNum": 844},
        {"name": "Cramorant", "dexNum": 845},
        {"name": "Barraskewda", "dexNum": 847},
        {"name": "Grapploct", "dexNum": 853},
        {"name": "Polteageist", "dexNum": 855},
        {"name": "Sinistcha", "dexNum": 1013},
        {"name": "Hattrem", "dexNum": 857},
        {"name": "Morgrem", "dexNum": 860},
        {"name": "Runerigus", "dexNum": 867},
        {"name": "Alcremie", "dexNum": 869},
        {"name": "Falinks", "dexNum": 870},
        {"name": "Pincurchin", "dexNum": 871},
        {"name": "Stonjourner", "dexNum": 874},
        {"name": "Eiscue", "dexNum": 875},
        {"name": "Indeedee", "dexNum": 876},
        {"name": "Morpeko", "dexNum": 877},
        {"name": "Copperajah", "dexNum": 879},
        {"name": "Vikavolt", "dexNum": 738},
        {"name": "Crabominable", "dexNum": 740},
        {"name": "Oricorio", "dexNum": 741},
        {"name": "Ribombee", "dexNum": 743},
        {"name": "Lycanroc", "dexNum": 745},
        {"name": "Wishiwashi", "dexNum": 746},
        {"name": "Toxapex", "dexNum": 748},
        {"name": "Mudsdale", "dexNum": 750},
        {"name": "Araquanid", "dexNum": 752},
        {"name": "Shiinotic", "dexNum": 756},
        {"name": "Steenee", "dexNum": 762},
        {"name": "Comfey", "dexNum": 764},
        {"name": "Oranguru", "dexNum": 765},
        {"name": "Passimian", "dexNum": 766},
        {"name": "Palossand", "dexNum": 770},
        {"name": "Komala", "dexNum": 775},
        {"name": "Togedemaru", "dexNum": 777},
        {"name": "Mimikyu", "dexNum": 778},
        {"name": "Bruxish", "dexNum": 779},
        {"name": "Talonflame", "dexNum": 663},
        {"name": "Vivillon", "dexNum": 666},
        {"name": "Pyroar", "dexNum": 668},
        {"name": "Floette", "dexNum": 670},
        {"name": "Gogoat", "dexNum": 673},
        {"name": "Furfrou", "dexNum": 676},
        {"name": "Meowstic", "dexNum": 678},
        {"name": "Doublade", "dexNum": 680},
        {"name": "Aromatisse", "dexNum": 683},
        {"name": "Slurpuff", "dexNum": 685},
        {"name": "Malamar", "dexNum": 687},
        {"name": "Barbaracle", "dexNum": 689},
        {"name": "Dragalge", "dexNum": 691},
        {"name": "Clawitzer", "dexNum": 693},
        {"name": "Heliolisk", "dexNum": 695},
        {"name": "Tyrunt", "dexNum": 696},
        {"name": "Amaura", "dexNum": 698},
        {"name": "Dedenne", "dexNum": 702},
        {"name": "Carbink", "dexNum": 703},
        {"name": "Klefki", "dexNum": 707},
        {"name": "Trevenant", "dexNum": 709},
        {"name": "Gourgeist", "dexNum": 711},
        {"name": "Avalugg", "dexNum": 713},
        {"name": "Toucannon", "dexNum": 733},
        {"name": "Stoutland", "dexNum": 508},
        {"name": "Musharna", "dexNum": 518},
        {"name": "Unfezant", "dexNum": 521},
        {"name": "Zebstrika", "dexNum": 523},
        {"name": "Boldore", "dexNum": 525},
        {"name": "Swoobat", "dexNum": 528},
        {"name": "Audino", "dexNum": 531},
        {"name": "Gurdurr", "dexNum": 533},
        {"name": "Palpitoad", "dexNum": 536},
        {"name": "Throh", "dexNum": 538},
        {"name": "Sawk", "dexNum": 539},
        {"name": "Leavanny", "dexNum": 542},
        {"name": "Whirlipede", "dexNum": 544},
        {"name": "Whimsicott", "dexNum": 547},
        {"name": "Lilligant", "dexNum": 549},
        {"name": "Basculin", "dexNum": 550},
        {"name": "Krokorok", "dexNum": 552},
        {"name": "Maractus", "dexNum": 556},
        {"name": "Crustle", "dexNum": 558},
        {"name": "Scrafty", "dexNum": 560},
        {"name": "Sigilyph", "dexNum": 561},
        {"name": "Cofagrigus", "dexNum": 563},
        {"name": "Tirtouga", "dexNum": 564},
        {"name": "Archen", "dexNum": 566},
        {"name": "Garbodor", "dexNum": 569},
        {"name": "Cinccino", "dexNum": 573},
        {"name": "Gothorita", "dexNum": 575},
        {"name": "Duosion", "dexNum": 578},
        {"name": "Swanna", "dexNum": 581},
        {"name": "Vanillish", "dexNum": 583},
        {"name": "Sawsbuck", "dexNum": 586},
        {"name": "Emolga", "dexNum": 587},
        {"name": "Escavalier", "dexNum": 589},
        {"name": "Amoonguss", "dexNum": 591},
        {"name": "Jellicent", "dexNum": 593},
        {"name": "Alomomola", "dexNum": 594},
        {"name": "Galvantula", "dexNum": 596},
        {"name": "Ferrothorn", "dexNum": 598},
        {"name": "Klang", "dexNum": 600},
        {"name": "Eelektrik", "dexNum": 603},
        {"name": "Beheeyem", "dexNum": 606},
        {"name": "Beartic", "dexNum": 614},
        {"name": "Cryogonal", "dexNum": 615},
        {"name": "Accelgor", "dexNum": 617},
        {"name": "Stunfisk", "dexNum": 618},
        {"name": "Mienfoo", "dexNum": 619},
        {"name": "Golett", "dexNum": 622},
        {"name": "Bisharp", "dexNum": 625},
        {"name": "Bouffalant", "dexNum": 626},
        {"name": "Rufflet", "dexNum": 627},
        {"name": "Vullaby", "dexNum": 629},
        {"name": "Heatmor", "dexNum": 631},
        {"name": "Durant", "dexNum": 632},
        {"name": "Staraptor", "dexNum": 398},
        {"name": "Bibarel", "dexNum": 400},
        {"name": "Luxio", "dexNum": 404},
        {"name": "Cranidos", "dexNum": 408},
        {"name": "Shieldon", "dexNum": 410},
        {"name": "Wormadam", "dexNum": 413},
        {"name": "Mothim", "dexNum": 414},
        {"name": "Pachirisu", "dexNum": 417},
        {"name": "Floatzel", "dexNum": 419},
        {"name": "Cherrim", "dexNum": 421},
        {"name": "Gastrodon", "dexNum": 423},
        {"name": "Drifblim", "dexNum": 426},
        {"name": "Lopunny", "dexNum": 428},
        {"name": "Purugly", "dexNum": 432},
        {"name": "Skuntank", "dexNum": 435},
        {"name": "Bronzong", "dexNum": 437},
        {"name": "Hippowdon", "dexNum": 450},
        {"name": "Drapion", "dexNum": 452},
        {"name": "Toxicroak", "dexNum": 454},
        {"name": "Carnivine", "dexNum": 455},
        {"name": "Lumineon", "dexNum": 457},
        {"name": "Abomasnow", "dexNum": 460},
        {"name": "Beautifly", "dexNum": 267},
        {"name": "Dustox", "dexNum": 269},
        {"name": "Lombre", "dexNum": 271},
        {"name": "Nuzleaf", "dexNum": 274},
        {"name": "Swellow", "dexNum": 277},
        {"name": "Pelipper", "dexNum": 279},
        {"name": "Kirlia", "dexNum": 281},
        {"name": "Masquerain", "dexNum": 284},
        {"name": "Breloom", "dexNum": 286},
        {"name": "Vigoroth", "dexNum": 288},
        {"name": "Ninjask", "dexNum": 291},
        {"name": "Shedinja", "dexNum": 292},
        {"name": "Exploud", "dexNum": 295},
        {"name": "Hariyama", "dexNum": 297},
        {"name": "Delcatty", "dexNum": 301},
        {"name": "Sableye", "dexNum": 302},
        {"name": "Lairon", "dexNum": 305},
        {"name": "Medicham", "dexNum": 308},
        {"name": "Manectric", "dexNum": 310},
        {"name": "Plusle", "dexNum": 311},
        {"name": "Minun", "dexNum": 312},
        {"name": "Volbeat", "dexNum": 313},
        {"name": "Illumise", "dexNum": 314},
        {"name": "Roselia", "dexNum": 315},
        {"name": "Swalot", "dexNum": 317},
        {"name": "Sharpedo", "dexNum": 319},
        {"name": "Wailord", "dexNum": 321},
        {"name": "Camerupt", "dexNum": 323},
        {"name": "Torkoal", "dexNum": 324},
        {"name": "Grumpig", "dexNum": 326},
        {"name": "Spinda", "dexNum": 327},
        {"name": "Vibrava", "dexNum": 329},
        {"name": "Cacturne", "dexNum": 332},
        {"name": "Altaria", "dexNum": 334},
        {"name": "Zangoose", "dexNum": 335},
        {"name": "Seviper", "dexNum": 336},
        {"name": "Lunatone", "dexNum": 337},
        {"name": "Solrock", "dexNum": 338},
        {"name": "Whiscash", "dexNum": 340},
        {"name": "Crawdaunt", "dexNum": 342},
        {"name": "Claydol", "dexNum": 344},
        {"name": "Lileep", "dexNum": 345},
        {"name": "Anorith", "dexNum": 347},
        {"name": "Castform", "dexNum": 351},
        {"name": "Kecleon", "dexNum": 352},
        {"name": "Banette", "dexNum": 354},
        {"name": "Dusclops", "dexNum": 356},
        {"name": "Tropius", "dexNum": 357},
        {"name": "Chimecho", "dexNum": 358},
        {"name": "Snorunt", "dexNum": 361},
        {"name": "Sealeo", "dexNum": 364},
        {"name": "Huntail", "dexNum": 367},
        {"name": "Gorebyss", "dexNum": 368},
        {"name": "Luvdisc", "dexNum": 370},
        {"name": "Togetic", "dexNum": 176},
        {"name": "Flaaffy", "dexNum": 180},
        {"name": "Azumarill", "dexNum": 184},
        {"name": "Jumpluff", "dexNum": 189},
        {"name": "Quagsire", "dexNum": 195},
        {"name": "Slowking", "dexNum": 199},
        {"name": "Unown", "dexNum": 201},
        {"name": "Wobbuffet", "dexNum": 202},
        {"name": "Forretress", "dexNum": 205},
        {"name": "Dunsparce", "dexNum": 206},
        {"name": "Gligar", "dexNum": 207},
        {"name": "Granbull", "dexNum": 210},
        {"name": "Qwilfish", "dexNum": 211},
        {"name": "Shuckle", "dexNum": 213},
        {"name": "Sneasel", "dexNum": 215},
        {"name": "Magcargo", "dexNum": 219},
        {"name": "Piloswine", "dexNum": 221},
        {"name": "Corsola", "dexNum": 222},
        {"name": "Octillery", "dexNum": 224},
        {"name": "Delibird", "dexNum": 225},
        {"name": "Mantine", "dexNum": 226},
        {"name": "Houndoom", "dexNum": 229},
        {"name": "Donphan", "dexNum": 232},
        {"name": "Porygon2", "dexNum": 233},
        {"name": "Stantler", "dexNum": 234},
        {"name": "Hitmontop", "dexNum": 237},
        {"name": "Miltank", "dexNum": 241},
        {"name": "Butterfree", "dexNum": 12},
        {"name": "Tauros", "dexNum": 128},
        {"name": "Beedrill", "dexNum": 15},
        {"name": "Pidgeot", "dexNum": 18},
        {"name": "Raticate", "dexNum": 20},
        {"name": "Fearow", "dexNum": 22},
        {"name": "Raichu", "dexNum": 26},
        {"name": "Sandslash", "dexNum": 28},
        {"name": "Nidoking", "dexNum": 34},
        {"name": "Nidoqueen", "dexNum": 31},
        {"name": "Clefable", "dexNum": 36},
        {"name": "Ninetales", "dexNum": 38},
        {"name": "Wigglytuff", "dexNum": 40},
        {"name": "Vileplume", "dexNum": 45},
        {"name": "Parasect", "dexNum": 47},
        {"name": "Venomoth", "dexNum": 49},
        {"name": "Dugtrio", "dexNum": 51},
        {"name": "Persian", "dexNum": 53},
        {"name": "Golduck", "dexNum": 55},
        {"name": "Primeape", "dexNum": 57},
        {"name": "Poliwrath", "dexNum": 62},
        {"name": "Victreebel", "dexNum": 71},
        {"name": "Tentacruel", "dexNum": 73},
        {"name": "Rapidash", "dexNum": 78},
        {"name": "Slowbro", "dexNum": 80},
        {"name": "Magneton", "dexNum": 82},
        {"name": "Farfetch’d", "dexNum": 83},
        {"name": "Dodrio", "dexNum": 85},
        {"name": "Dewgong", "dexNum": 87},
        {"name": "Muk", "dexNum": 89},
        {"name": "Cloyster", "dexNum": 91},
        {"name": "Haunter", "dexNum": 93},
        {"name": "Hypno", "dexNum": 97},
        {"name": "Kingler", "dexNum": 99},
        {"name": "Electrode", "dexNum": 101},
        {"name": "Exeggutor", "dexNum": 103},
        {"name": "Marowak", "dexNum": 105},
        {"name": "Weezing", "dexNum": 110},
        {"name": "Rhydon", "dexNum": 112},
        {"name": "Seadra", "dexNum": 117},
        {"name": "Seaking", "dexNum": 119},
        {"name": "Starmie", "dexNum": 121},
        {"name": "Jynx", "dexNum": 124},
        {"name": "Mr. Mime", "dexNum": 122},
        {"name": "Electabuzz", "dexNum": 125},
        {"name": "Magmar", "dexNum": 126},
        {"name": "Scyther", "dexNum": 123},
        {"name": "Hitmonchan", "dexNum": 107},
        {"name": "Hitmonlee", "dexNum": 106},
        {"name": "Lickitung", "dexNum": 108},
        {"name": "Tinkatuff", "dexNum": 958},
        {"name": "Hawlucha", "dexNum": 701},
        {"name": "Tangrowth", "dexNum": 465},
        {"name": "Lickilicky", "dexNum": 463},
        {"name": "Probopass", "dexNum": 476},
        {"name": "Arctibax", "dexNum": 997},
        {"name": "Duraludon", "dexNum": 884},
        {"name": "Drakloak", "dexNum": 886},
        {"name": "Drizzile", "dexNum": 817},
        {"name": "Raboot", "dexNum": 814},
        {"name": "Thwackey", "dexNum": 811},
        {"name": "Hakamo-o", "dexNum": 783},
        {"name": "Brionne", "dexNum": 729},
        {"name": "Torracat", "dexNum": 726},
        {"name": "Dartrix", "dexNum": 723},
        {"name": "Fraxure", "dexNum": 611},
        {"name": "Lampent", "dexNum": 608},
        {"name": "Sliggoo", "dexNum": 705},
        {"name": "Frogadier", "dexNum": 657},
        {"name": "Braixen", "dexNum": 654},
        {"name": "Quilladin", "dexNum": 651},
        {"name": "Larvesta", "dexNum": 636},
        {"name": "Zweilous", "dexNum": 634},
        {"name": "Gabite", "dexNum": 444},
        {"name": "Dewott", "dexNum": 502},
        {"name": "Pignite", "dexNum": 499},
        {"name": "Servine", "dexNum": 496},
        {"name": "Prinplup", "dexNum": 394},
        {"name": "Monferno", "dexNum": 391},
        {"name": "Grotle", "dexNum": 388},
        {"name": "Metang", "dexNum": 375},
        {"name": "Shelgon", "dexNum": 372},
        {"name": "Pupitar", "dexNum": 247},
        {"name": "Marshtomp", "dexNum": 259},
        {"name": "Combusken", "dexNum": 256},
        {"name": "Grovyle", "dexNum": 253},
        {"name": "Floragato", "dexNum": 907},
        {"name": "Crocalor", "dexNum": 910},
        {"name": "Quaxwell", "dexNum": 913},
        {"name": "Dragonair", "dexNum": 148},
        {"name": "Bayleef", "dexNum": 153},
        {"name": "Quilava", "dexNum": 156},
        {"name": "Croconaw", "dexNum": 159},
        {"name": "Arcanine", "dexNum": 59},
        {"name": "Ivysaur", "dexNum": 2},
        {"name": "Charmeleon", "dexNum": 5},
        {"name": "Wartortle", "dexNum": 8},
        {"name": "Ursaring", "dexNum": 217}
    ],
    /* Tier 4 */ [

        {"name": "Ampharos", "dexNum": 181},
        {"name": "Mandibuzz", "dexNum": 630},
        {"name": "Garganacl", "dexNum": 934},
        {"name": "Armarouge", "dexNum": 936},
        {"name": "Ceruledge", "dexNum": 937},
        {"name": "Espathra", "dexNum": 956},
        {"name": "Palafin", "dexNum": 964},
        {"name": "Revavroom", "dexNum": 966},
        {"name": "Cyclizar", "dexNum": 967},
        {"name": "Annihilape", "dexNum": 979},
        {"name": "Clodsire", "dexNum": 980},
        {"name": "Farigiraf", "dexNum": 981},
        {"name": "Dudunsparce", "dexNum": 982},
        {"name": "Hydrapple", "dexNum": 1019},
        {"name": "Coalossal", "dexNum": 839},
        {"name": "Toxtricity", "dexNum": 849},
        {"name": "Centiskorch", "dexNum": 851},
        {"name": "Hatterene", "dexNum": 858},
        {"name": "Grimmsnarl", "dexNum": 861},
        {"name": "Obstagoon", "dexNum": 862},
        {"name": "Perrserker", "dexNum": 863},
        {"name": "Cursola", "dexNum": 864},
        {"name": "Sirfetch’d", "dexNum": 865},
        {"name": "Mr. Rime", "dexNum": 866},
        {"name": "Frosmoth", "dexNum": 873},
        {"name": "Wyrdeer", "dexNum": 899},
        {"name": "Kleavor", "dexNum": 900},
        {"name": "Ursaluna", "dexNum": 901},
        {"name": "Basculegion", "dexNum": 902},
        {"name": "Sneasler", "dexNum": 903},
        {"name": "Overqwil", "dexNum": 904},
        {"name": "Lurantis", "dexNum": 754},
        {"name": "Salazzle", "dexNum": 758},
        {"name": "Bewear", "dexNum": 760},
        {"name": "Tsareena", "dexNum": 763},
        {"name": "Golisopod", "dexNum": 768},
        {"name": "Minior", "dexNum": 774},
        {"name": "Turtonator", "dexNum": 776},
        {"name": "Drampa", "dexNum": 780},
        {"name": "Dhelmise", "dexNum": 781},
        {"name": "Florges", "dexNum": 671},
        {"name": "Pangoro", "dexNum": 675},
        {"name": "Aegislash", "dexNum": 681},
        {"name": "Tyrantrum", "dexNum": 697},
        {"name": "Aurorus", "dexNum": 699},
        {"name": "Gigalith", "dexNum": 526},
        {"name": "Excadrill", "dexNum": 530},
        {"name": "Conkeldurr", "dexNum": 534},
        {"name": "Seismitoad", "dexNum": 537},
        {"name": "Scolipede", "dexNum": 545},
        {"name": "Krookodile", "dexNum": 553},
        {"name": "Darmanitan", "dexNum": 555},
        {"name": "Carracosta", "dexNum": 565},
        {"name": "Archeops", "dexNum": 567},
        {"name": "Zoroark", "dexNum": 571},
        {"name": "Gothitelle", "dexNum": 576},
        {"name": "Reuniclus", "dexNum": 579},
        {"name": "Vanilluxe", "dexNum": 584},
        {"name": "Klinklang", "dexNum": 601},
        {"name": "Eelektross", "dexNum": 604},
        {"name": "Mienshao", "dexNum": 620},
        {"name": "Druddigon", "dexNum": 621},
        {"name": "Golurk", "dexNum": 623},
        {"name": "Braviary", "dexNum": 628},
        {"name": "Luxray", "dexNum": 405},
        {"name": "Roserade", "dexNum": 407},
        {"name": "Rampardos", "dexNum": 409},
        {"name": "Bastiodon", "dexNum": 411},
        {"name": "Vespiquen", "dexNum": 416},
        {"name": "Ambipom", "dexNum": 424},
        {"name": "Mismagius", "dexNum": 429},
        {"name": "Honchkrow", "dexNum": 430},
        {"name": "Spiritomb", "dexNum": 442},
        {"name": "Lucario", "dexNum": 448},
        {"name": "Rotom", "dexNum": 479},
        {"name": "Ludicolo", "dexNum": 272},
        {"name": "Shiftry", "dexNum": 275},
        {"name": "Gardevoir", "dexNum": 282},
        {"name": "Slaking", "dexNum": 289},
        {"name": "Mawile", "dexNum": 303},
        {"name": "Aggron", "dexNum": 306},
        {"name": "Flygon", "dexNum": 330},
        {"name": "Cradily", "dexNum": 346},
        {"name": "Armaldo", "dexNum": 348},
        {"name": "Milotic", "dexNum": 350},
        {"name": "Absol", "dexNum": 359},
        {"name": "Glalie", "dexNum": 362},
        {"name": "Walrein", "dexNum": 365},
        {"name": "Relicanth", "dexNum": 369},
        {"name": "Crobat", "dexNum": 169},
        {"name": "Steelix", "dexNum": 208},
        {"name": "Scizor", "dexNum": 212},
        {"name": "Heracross", "dexNum": 214},
        {"name": "Skarmory", "dexNum": 227},
        {"name": "Kingdra", "dexNum": 230},
        {"name": "Blissey", "dexNum": 242},
        {"name": "Alakazam", "dexNum": 65},
        {"name": "Machamp", "dexNum": 68},
        {"name": "Gengar", "dexNum": 94},
        {"name": "Gyarados", "dexNum": 130},
        {"name": "Lapras", "dexNum": 131},
        {"name": "Ditto", "dexNum": 132},
        {"name": "Omastar", "dexNum": 139},
        {"name": "Kabutops", "dexNum": 141},
        {"name": "Aerodactyl", "dexNum": 142},
        {"name": "Snorlax", "dexNum": 143},
        {"name": "Pinsir", "dexNum": 127},
        {"name": "Tinkaton", "dexNum": 959},
        {"name": "Bramaluna", "dexNum": 1005},
        {"name": "Ferropaladín", "dexNum": 1006},
        {"name": "Archaludon", "dexNum": 1018},
        {"name": "Sylveon", "dexNum": 700},
        {"name": "Weavile", "dexNum": 461},
        {"name": "Magnezone", "dexNum": 462},
        {"name": "Rhyperior", "dexNum": 464},
        {"name": "Electivire", "dexNum": 466},
        {"name": "Magmortar", "dexNum": 467},
        {"name": "Togekiss", "dexNum": 468},
        {"name": "Yanmega", "dexNum": 469},
        {"name": "Leafeon", "dexNum": 470},
        {"name": "Glaceon", "dexNum": 471},
        {"name": "Gliscor", "dexNum": 472},
        {"name": "Mamoswine", "dexNum": 473},
        {"name": "Porygon-Z", "dexNum": 474},
        {"name": "Gallade", "dexNum": 475},
        {"name": "Dusknoir", "dexNum": 477},
        {"name": "Froslass", "dexNum": 478},
        {"name": "Vaporeon", "dexNum": 134},
        {"name": "Jolteon", "dexNum": 135},
        {"name": "Flareon", "dexNum": 136},
        {"name": "Baxcalibur", "dexNum": 998},
        {"name": "Dracozolt", "dexNum": 880},
        {"name": "Arctozolt", "dexNum": 881},
        {"name": "Dracovish", "dexNum": 882},
        {"name": "Arctovish", "dexNum": 883},
        {"name": "Dragapult", "dexNum": 887},
        {"name": "Inteleon", "dexNum": 818},
        {"name": "Cinderace", "dexNum": 815},
        {"name": "Rillaboom", "dexNum": 812},
        {"name": "Kommo-o", "dexNum": 784},
        {"name": "Noivern", "dexNum": 715},
        {"name": "Decidueye", "dexNum": 724},
        {"name": "Incineroar", "dexNum": 727},
        {"name": "Primarina", "dexNum": 730},
        {"name": "Chandelure", "dexNum": 609},
        {"name": "Haxorus", "dexNum": 612},
        {"name": "Goodra", "dexNum": 706},
        {"name": "Greninja", "dexNum": 658},
        {"name": "Delphox", "dexNum": 655},
        {"name": "Chesnaught", "dexNum": 652},
        {"name": "Volcarona", "dexNum": 637},
        {"name": "Hydreigon", "dexNum": 635},
        {"name": "Garchomp", "dexNum": 445},
        {"name": "Samurott", "dexNum": 503},
        {"name": "Emboar", "dexNum": 500},
        {"name": "Serperior", "dexNum": 497},
        {"name": "Empoleon", "dexNum": 395},
        {"name": "Infernape", "dexNum": 392},
        {"name": "Torterra", "dexNum": 389},
        {"name": "Metagross", "dexNum": 376},
        {"name": "Salamence", "dexNum": 373},
        {"name": "Swampert", "dexNum": 260},
        {"name": "Blaziken", "dexNum": 257},
        {"name": "Sceptile", "dexNum": 254},
        {"name": "Tyranitar", "dexNum": 248},
        {"name": "Quaquaval", "dexNum": 914},
        {"name": "Dragonite", "dexNum": 149},
        {"name": "Meganium", "dexNum": 154},
        {"name": "Typhlosion", "dexNum": 157},
        {"name": "Feraligatr", "dexNum": 160},
        {"name": "Venusaur", "dexNum": 3},
        {"name": "Blastoise", "dexNum": 9},
        {"name": "Meowscarada", "dexNum": 908},
        {"name": "Espeon", "dexNum": 196},
        {"name": "Umbreon", "dexNum": 197},
        {"name": "Charizard", "dexNum": 6},
        {"name": "Skeledirge", "dexNum": 911},
        {"name": "Kingambit", "dexNum": 983},
        {"name": "Colmilargo", "dexNum": 984},
        {"name": "Colagrito", "dexNum": 985},
        {"name": "Furioseta", "dexNum": 986},
        {"name": "Melenaleteo", "dexNum": 987},
        {"name": "Reptalada", "dexNum": 988},
        {"name": "Pelarena", "dexNum": 989},
        {"name": "Ferrodada", "dexNum": 990},
        {"name": "Ferrosaco", "dexNum": 991},
        {"name": "Ferropalmas", "dexNum": 992},
        {"name": "Ferrocuello", "dexNum": 993},
        {"name": "Ferropolilla", "dexNum": 994},
        {"name": "Ferropúas", "dexNum": 995},
        {"name": "Gholdengo", "dexNum": 1000}
    ],
    /* Tier 5 */ [
        
        {"name": "Articuno", "dexNum": 144},
        {"name": "Zapdos", "dexNum": 145},
        {"name": "Moltres", "dexNum": 146},
        {"name": "Okidogi", "dexNum": 1014},
        {"name": "Munkidori", "dexNum": 1015},
        {"name": "Fezandipiti", "dexNum": 1016},
        {"name": "Mewtwo", "dexNum": 150},
        {"name": "Raikou", "dexNum": 243},
        {"name": "Entei", "dexNum": 244},
        {"name": "Suicune", "dexNum": 245},
        {"name": "Lugia", "dexNum": 249},
        {"name": "Ho-Oh", "dexNum": 250},
        {"name": "Regirock", "dexNum": 377},
        {"name": "Regice", "dexNum": 378},
        {"name": "Registeel", "dexNum": 379},
        {"name": "Latias", "dexNum": 380},
        {"name": "Latios", "dexNum": 381},
        {"name": "Kyogre", "dexNum": 382},
        {"name": "Groudon", "dexNum": 383},
        {"name": "Rayquaza", "dexNum": 384},
        {"name": "Uxie", "dexNum": 480},
        {"name": "Mesprit", "dexNum": 481},
        {"name": "Azelf", "dexNum": 482},
        {"name": "Dialga", "dexNum": 483},
        {"name": "Palkia", "dexNum": 484},
        {"name": "Heatran", "dexNum": 485},
        {"name": "Regigigas", "dexNum": 486},
        {"name": "Giratina", "dexNum": 487},
        {"name": "Cresselia", "dexNum": 488},
        {"name": "Cobalion", "dexNum": 638},
        {"name": "Terrakion", "dexNum": 639},
        {"name": "Virizion", "dexNum": 640},
        {"name": "Tornadus", "dexNum": 641},
        {"name": "Thundurus", "dexNum": 642},
        {"name": "Reshiram", "dexNum": 643},
        {"name": "Zekrom", "dexNum": 644},
        {"name": "Landorus", "dexNum": 645},
        {"name": "Kyurem", "dexNum": 646},
        {"name": "Xerneas", "dexNum": 716},
        {"name": "Yveltal", "dexNum": 717},
        {"name": "Zygarde", "dexNum": 718},
        {"name": "Código Cero", "dexNum": 772},
        {"name": "Silvally", "dexNum": 773},
        {"name": "Tapu Koko", "dexNum": 785},
        {"name": "Tapu Lele", "dexNum": 786},
        {"name": "Tapu Bulu", "dexNum": 787},
        {"name": "Tapu Fini", "dexNum": 788},
        {"name": "Cosmog", "dexNum": 789},
        {"name": "Cosmoem", "dexNum": 790},
        {"name": "Solgaleo", "dexNum": 791},
        {"name": "Lunala", "dexNum": 792},
        {"name": "Nihilego", "dexNum": 793},
        {"name": "Buzzwole", "dexNum": 794},
        {"name": "Pheromosa", "dexNum": 795},
        {"name": "Xurkitree", "dexNum": 796},
        {"name": "Celesteela", "dexNum": 797},
        {"name": "Kartana", "dexNum": 798},
        {"name": "Guzzlord", "dexNum": 799},
        {"name": "Necrozma", "dexNum": 800},
        {"name": "Poipole", "dexNum": 803},
        {"name": "Naganadel", "dexNum": 804},
        {"name": "Stakataka", "dexNum": 805},
        {"name": "Blacephalon", "dexNum": 806},
        {"name": "Zacian", "dexNum": 888},
        {"name": "Zamazenta", "dexNum": 889},
        {"name": "Eternatus", "dexNum": 890},
        {"name": "Kubfu", "dexNum": 891},
        {"name": "Urshifu", "dexNum": 892},
        {"name": "Regieleki", "dexNum": 894},
        {"name": "Regidrago", "dexNum": 895},
        {"name": "Glastrier", "dexNum": 896},
        {"name": "Spectrier", "dexNum": 897},
        {"name": "Calyrex", "dexNum": 898},
        {"name": "Enamorus", "dexNum": 905},
        {"name": "Wo-Chien", "dexNum": 1001},
        {"name": "Chien-Pao", "dexNum": 1002},
        {"name": "Ting-Lu", "dexNum": 1003},
        {"name": "Chi-Yu", "dexNum": 1004},
        {"name": "Koraidon", "dexNum": 1007},
        {"name": "Miraidon", "dexNum": 1008},
        {"name": "Ondulagua", "dexNum": 1009},
        {"name": "Ferroverdor", "dexNum": 1010},
        {"name": "Flamariete", "dexNum": 1020},
        {"name": "Electrofuria", "dexNum": 1021},
        {"name": "Ferromole", "dexNum": 1022},
        {"name": "Ferrotesta", "dexNum": 1023},
        {"name": "Terapagos", "dexNum": 1024},
        {"name": "Ogerpon", "dexNum": 1017}
            
    ],
    /* Tier 6 */ [
        {"name": "Mew", "dexNum": 151},
        {"name": "Celebi", "dexNum": 251},
        {"name": "Jirachi", "dexNum": 385},
        {"name": "Deoxys", "dexNum": 386},
        {"name": "Phione", "dexNum": 489},
        {"name": "Manaphy", "dexNum": 490},
        {"name": "Darkrai", "dexNum": 491},
        {"name": "Shaymin", "dexNum": 492},
        {"name": "Arceus", "dexNum": 493},
        {"name": "Victini", "dexNum": 494},
        {"name": "Keldeo", "dexNum": 647},
        {"name": "Meloetta", "dexNum": 648},
        {"name": "Genesect", "dexNum": 649},
        {"name": "Diancie", "dexNum": 719},
        {"name": "Hoopa", "dexNum": 720},
        {"name": "Volcanion", "dexNum": 721},
        {"name": "Magearna", "dexNum": 801},
        {"name": "Marshadow", "dexNum": 802},
        {"name": "Zeraora", "dexNum": 807},
        {"name": "Meltan", "dexNum": 808},
        {"name": "Melmetal", "dexNum": 809},
        {"name": "Zarude", "dexNum": 893},
        {"name": "Pecharunt", "dexNum": 1025}
]
];